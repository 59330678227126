
// Regex to match any valid date in the format dd.mm.yyyy where the leading zeros are optional
// and the year can be given as two digit year.
// See https://www.debuggex.com/r/8NdvgefJSO_wD6pc
// eslint-disable-next-line no-useless-escape
const DATE_REGEX = RegExp(/^(?:(?:(?:0?[1-9]|1\d|2[0-8])\.(?:0?[1-9]|1[0-2])|(?:29|30)\.(?:0?[13-9]|1[0-2])|31\.(?:0?[13578]|1[02]))\.(?:(?:[1-9]\d{3})|\d{2})|29\.0?2\.(?:(?:[1-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:[2468][048]|[13579][26])00))$/)

function dateToISO(year, month, day) {
  year = parseInt(year)
  year = year < 99 ? year + 2000 : year
  year = ('0000' + year).substr(-4)
  month = ('00' + month).substr(-2)
  day = ('00' + day).substr(-2)
  return `${year}-${month}-${day}`
}

function dateISOtoCH(date) {
  return date.split('-').reverse().join('.')
}

function dateCHtoISO(date) {
  return dateToISO(...date.split('.').reverse())
}

function dateIsValid(date) {
  return !!DATE_REGEX.exec(date)
}

function dateDMYtoDDMMYYYY(date) {
  const match = (/(\d+).(\d+).(\d+)/g).exec(date)
  let nVal = date
  if (match) {
    let year = parseInt(match[3])
    if (year < 100) { year += 2000 }
    nVal = `${('00' + match[1]).substr(-2)}.${('00' + match[2]).substr(-2)}.${year}`
  }
  return nVal
}


class DateOnly {
  constructor(year, month, day) {
    this.year = year
    this.month = month
    this.day = day
  }

  static fromString(date, separator = '.', order = 'dmy') {
    let parts = date.split(separator).map(e => parseInt(e))
    if(order === 'dmy') {
      parts = parts.reverse()
    }
    return new DateOnly(...parts)
  }

  static fromDate(date, utc = true) {
    if(utc) {
      return new DateOnly(date.getUTCFullYear(), date.getUTCMonth() + 1, date.getUTCDate())
    } else {
      return new DateOnly(date.getFullYear(), date.getMonth() + 1, date.getDate())
    }
  }

  /**
   * Get the date of today as DateOnly Object
   * @param {Boolean} utc
   */
  static today(utc = false) {
    return DateOnly.fromDate(new Date(), utc)
  }

  /**
   * Format the date as ISO Date string
   */
  toISO() {
    return dateToISO(this.year, this.month, this.day)
  }

  /**
   * Convert the DateOnly object to a Date object
   */
  toDate() {
    return new Date(this.year, this.month - 1, this.day, 0, 0)
  }

  /**
   * Calculate the days until another date.
   * Supported types: String (in CH Format), DateOnly, Date.
   * @param {*} other
   */
  daysUntil(other) {
    const thisDate = this.toDate()
    const oneDay = 1000 * 60 * 60 * 24

    if(other instanceof DateOnly) {
      other = other.toDate()
    } else if(typeof other === 'string') {
      other = DateOnly.fromString(other).toDate()
    }

    const timeDiff = other.getTime() - thisDate.getTime()
    return Math.floor(timeDiff / oneDay)
  }
}

export {
  dateISOtoCH,
  dateCHtoISO,
  dateIsValid,
  dateDMYtoDDMMYYYY,
  DateOnly,
}

// Vue basic configuration
import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import router from '../router'

let samplingRate = 1.0
let environment = 'develop'
if(process.env.NODE_ENV === 'production') {
  samplingRate = 0.2
  environment = 'production'
} else {
  samplingRate = 1.0
  environment = 'develop'
}


Sentry.init({
  Vue,
  dsn: 'https://201254733a93443491e42b13cbb16ae6@o90008.ingest.sentry.io/196168',
  release: process.env.VUE_APP_RELEASE,
  environment: environment,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'ectrl.jrast.ch', 'touren.sac-toedi.ch', /^\//],
    }),
  ],
  tracesSampleRate: samplingRate,
})


import Transformer from './Transformer'

import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'


function keysToCamelCase(obj) {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v]) => [camelCase(k), v]),
  )
}

function keysToSnakeCase(obj) {
  return Object.fromEntries(
    Object.entries(obj).map(([k, v]) => [snakeCase(k), v]),
  )
}


export default class MediaTransformer extends Transformer {
  static fetch(item) {
    return keysToCamelCase(item)
  }

  static send(item) {
    return keysToSnakeCase(item)
  }
}

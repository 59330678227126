import { Ability, AbilityBuilder } from '@casl/ability'
import includes from 'lodash/includes'


function hasRole(user, role) {
  return includes(user.roles, role)
}

function isAuthenticated(user) {
  return !!user.id
}

export default function defineAbilityFor(user) {
  const { can, rules } = new AbilityBuilder(Ability)
  // console.log('--- Define Ability For ---')
  // console.log('User ID:', user.id)
  // console.log('User Roles:', JSON.stringify(user.roles))
  // console.log('Linked Person:', user.person_id)
  // console.log('Organisation:', JSON.stringify(user.organisations))

  // Public Access
  can('view', 'Route', { 'meta.public': true })


  // Non autheticated user
  if (!isAuthenticated(user)) {
    return rules
  }

  // Administrator Rules
  if (hasRole(user, 'admin')) {
    can('manage', 'all')
    return rules
  }

  // Route access based on role
  // Access to routes is granted as long as the roles are not clearly defined.
  // Cases where meta or meta.roles is not defined for the route
  can('view', 'Route', { meta: { $exists: false } })
  can('view', 'Route', { 'meta.roles': { $exists: false } })
  // Cases where meta.roles is defined
  can('view', 'Route', { 'meta.roles': { $exists: true, $size: 0 } }) // But Empty
  can('view', 'Route', { 'meta.roles': { $in: user.roles } }) // Contains roles


  // Manager Rules
  if (hasRole(user, 'manager')) {
    can('publish', 'Event')
    can('publish', 'Photo')

    can('edit', 'Event')
    can('update', 'Event', ['participants', 'guides', 'notes', 'report'])

    // Managers can see on which events a person participated
    can('view', 'Person', ['details', 'participatedEvents'])
  }

  // Guide Rules
  if (hasRole(user, 'guide')) {
    can('edit', 'Event')
    can('update', 'Event', ['participants', 'guides', 'notes', 'report'])
  }

  // Media Rules
  if (hasRole(user, 'media')) {
    can('publish', 'Photo')
  }
  return rules
}

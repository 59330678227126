import { loadView } from './helpers'

export default {
  path: '/mail',
  component: loadView('mail/Main'),
  meta: {
    auth: true,
    roles: ['admin', 'manager'],
  },
  children: [
    {
      name: 'MailDashboard',
      path: '/',
      component: loadView('mail/Dashboard'),
    },
    {
      name: 'MailCreate',
      path: 'create/:id([a-zA-Z0-9]+)?',
      component: loadView('mail/MailEditor'),
    },
    {
      name: 'MailLists',
      path: 'lists',
      component: loadView('mail/Lists'),
    },
    {
      name: 'MailListEdit',
      path: 'lists/edit/:address(\\S+)?',
      component: loadView('mail/ListEditor'),
    },
    {
      name: 'MailStatistics',
      path: 'statistics',
      component: loadView('mail/Statistics'),
      meta: {
        roles: ['admin'],
      },
    },
  ],
}

import 'core-js'
import Vue from 'vue'
import VueRouter from 'vue-router'

// Include all plugins (no further action is needed)
import vuetify from './plugins/vuetify'
import './plugins/sentry'
import './plugins/virtualscroller'

// Register Filters and Functional Components
import './utils/filters'
import './components/functional'
import './components/globalComponents'

// Import own styles
import './assets/style/main.scss'

import router from './router'
import './registerServiceWorker'
import { pinia } from '@/stores'
import { useAuthStore } from '@/stores/auth'

// Permission management
import { abilitiesPlugin } from '@casl/vue'


import fps from 'fps-indicator'


if(process.env.NODE_ENV !== 'production') {
  fps({
    position: 'top-right',
    period: 250,
    style: `
      font-size: 14px;
      z-index: 100;
    `,
  })
}


Vue.config.productionTip = false
Vue.config.performance = false

// Must be after PiniaVuePlugin, see https://github.com/vuejs/pinia/discussions/723#discussioncomment-2110660
Vue.use(VueRouter)


const app = new Vue({
  render: h => h('router-view'),
  router,
  vuetify,
  pinia,
})


// Register abilities after the app is created. Otherwise the store is accessed to early.
const authStore = useAuthStore()
Vue.use(abilitiesPlugin, authStore.ability, { useGlobalProperties: true })

app.$mount('#app')

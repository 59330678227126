import cloneDeep from 'lodash/cloneDeep'

import axios from '@/plugins/axios'
import EventTransformer from '@/transformers/EventTransformer'
import MediaTransformer from '@/transformers/MediaTransformer'

const END_POINT = '/api/v1/events'


function meta() {
  return axios.get(`${END_POINT}/meta`)
    .then(response => {
      return response.data
    })
}

function statistics() {
  return axios.get(`${END_POINT}/statistics`)
    .then(response => {
      return response.data
    })
}

function list(filters = {}, perPage = 300) {
  filters = cloneDeep(filters)
  if (filters.orgs && filters.orgs.length) {
    filters.orgs = filters.orgs.join(',')
  } else {
    delete filters.orgs
  }
  const params = {
    per_page: perPage,
    ...filters,
  }
  return axios.get(END_POINT, { params })
    .then(response => {
      response.data.items = EventTransformer.fetchCollection(response.data.items)
      return response
    })
}

function listPage(url) {
  return axios.get(url)
    .then(response => {
      const data = response.data
      data.items = EventTransformer.fetchCollection(data.items)
      return data
    })
}

function get(id) {
  return axios.get(`${END_POINT}/${id}`)
    .then((response) => {
      return EventTransformer.fetch(response.data)
    })
}

function create(event) {
  return axios.post(END_POINT, EventTransformer.send(event))
    .then((response) => {
      return EventTransformer.fetch(response.data)
    })
}

function update(event) {
  return axios.put(`${END_POINT}/${event.id}`, EventTransformer.send(event))
    .then((response) => {
      return EventTransformer.fetch(response.data)
    })
}

function remove(event) {
  return axios.delete(`${END_POINT}/${event.id}`)
    .then(response => {
      return event
    })
}

function removeUpload(event) {
  return axios.get(`${END_POINT}/${event.id}/removePDF`)
    .then(response => {
      return EventTransformer.fetch(response.data)
    })
}

function addUpload(event, form) {
  console.log(`Adding event detail sheet to event ${event.id}`)
  return axios.put(`${END_POINT}/${event.id}`, form)
    .then((response) => {
      return EventTransformer.fetch(response.data)
    })
}


function getPhotos(eventId) {
  return axios.get(`${END_POINT}/${eventId}/photos`)
    .then(response => {
      return MediaTransformer.fetchCollection(response.data)
    })
}

function addPhoto(eventId, photoId) {
  return axios.post(`${END_POINT}/${eventId}/photos`, {
    image: photoId,
  }).then(response => {
    return MediaTransformer.fetch(response.data)
  })
}

function setPhotoOrder(eventId, photoIds) {
  return axios.post(`${END_POINT}/${eventId}/photos/order`, {
    images: photoIds,
  }).then(response => {
    return MediaTransformer.fetchCollection(response.data)
  })
}

function getReport(eventId) {
  return axios.get(`${END_POINT}/${eventId}/report`)
    .then(response => response.data)
}

function saveReport(eventId, status, report) {
  return axios.post(`${END_POINT}/${eventId}/report`, {
    status: status,
    report: report,
  }).then(response => response.data)
}

export default {
  meta,
  statistics,
  list,
  listPage,
  get,
  create,
  update,
  remove,
  removeUpload,
  addUpload,
  getPhotos,
  addPhoto,
  setPhotoOrder,
  getReport,
  saveReport,
}

import axios from 'axios'

import router from '../router'
import { useAuthStore } from '@/stores/auth'

if(process.env.VUE_APP_PWA_TEST) {
  axios.defaults.baseURL = 'http://localhost:5000'
}

let isAlreadyFetchingAccessToken = false
let subscribers = []

function setAuthHeader(config, token) {
  config.headers.Authorization = 'Bearer ' + token
}

axios.defaults.headers.common.Accept = 'application/json'

axios.interceptors.request.use(
  config => {
    // Get the token from the store.
    const token = useAuthStore().token
    if(!config.noAuthHeader && token) {
      setAuthHeader(config, token)
    }
    return config
  },
)

axios.interceptors.response.use(
  response => response,
  error => {
    const { config, response } = error
    const originalRequest = config

    if(response && response.status === 401 && !config.skipRetry) {
      if(!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true
        useAuthStore().fetchAccessToken().then((token) => {
          isAlreadyFetchingAccessToken = false
          subscribers = subscribers.filter(callback => callback(token))
        }).catch(() => {
          isAlreadyFetchingAccessToken = false
          router.push({ name: 'Login' })
        })
      }

      const retryOriginalRequest = new Promise((resolve) => {
        subscribers.push((token) => {
          // Don't try to fetch the access token more than once.
          originalRequest.skipRetry = true
          // Replace the authorization header in the already configured request
          setAuthHeader(originalRequest, token)
          resolve(axios(originalRequest))
        })
      })

      return retryOriginalRequest
    } else {
      return Promise.reject(error)
    }
  },
)


export default axios

import { loadView } from './helpers'

export default {
  path: '/photos',
  component: loadView('photos/Main'),
  meta: {
    auth: true,
    roles: ['admin', 'manager', 'guide', 'media'],
  },
  children: [
    {
      name: 'PhotoDashboard',
      path: '/',
      component: loadView('photos/Dashboard'),
    },
    {
      name: 'EventPhotos',
      path: 'event/:id(\\d+)',
      component: loadView('photos/EventPhotos'),
    },
  ],
}

import defineAbilityFor from '@/ability'
import authService from '@/services/auth'
import { Ability } from '@casl/ability'
import * as Sentry from '@sentry/browser'
import includes from 'lodash/includes'
import { defineStore } from 'pinia'

function decodeToken(token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace('-', '+').replace('_', '/')
  const payload = JSON.parse(window.atob(base64))
  return payload
}

export const useAuthStore = defineStore('auth', {
  state: () => ({
    id: undefined,
    roles: [],
    token: undefined,
    initialized: false,
  }),
  actions: {
    login({ email, password }) {
      return authService.login(email, password)
        .then(data => {
          return this.tokenLogin(data.token)
        })
    },
    tokenLogin(token) {
      // If no token is provided, perform a clean logout
      if(!token) {
        this.logout()
      }

      // Decode the token
      const decoded = decodeToken(token)

      // If the token is expired, perform a clean logout
      if(decoded.exp * 1000 < Date.now()) {
        this.logout()
      }

      // Login
      this.token = token
      this.roles = decoded.roles
      this.id = decoded.sub
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: decoded.sub,
        })
      })

      // Update the abilities
      this.ability.update(defineAbilityFor(this.$state))
      console.log('New Abilities:', JSON.parse(JSON.stringify(this.ability.rules)))

      // If a admin logs in, save the token to the local state
      if(includes(decoded.roles, 'admin') && !localStorage.getItem('realUser')) {
        localStorage.setItem('realUser', JSON.stringify({ token: token }))
      }

      this.initialized = true

      // Setup a timeout to refresh the token
      const expiresIn = decoded.exp * 1000 - Date.now() // Token expiry in milliseconds from now
      const nextRefresh = Math.max(expiresIn - 60 * 1000, 10000) // Next refresh time in milliseconds from now
      const store = this
      setTimeout(function () {
        try {
          store.fetchAccessToken()
        } catch (error) {
          // console.log('Token refresh failed. Error: ', error)
        }
      }, nextRefresh)
    },

    fetchAccessToken() {
      return authService.refresh()
        .then(data => {
          this.tokenLogin(data.token)
          return data.token
        }).catch(_ => {
          this.logout()
          return undefined
        })
    },

    logout() {
      authService.logout()
      this.token = undefined
      this.roles = []
      this.id = undefined
      Sentry.configureScope((scope) => {
        scope.setUser({})
      })
      localStorage.removeItem('realUser')
      this.ability.update(defineAbilityFor(this.$state))
      // console.log('New Abilities:', JSON.parse(JSON.stringify(getters.ability.rules)))
    },

    changePassword({ oldPwd, newPwd, newPwdRepeated }) {
      return authService.pwdChange(oldPwd, newPwd, newPwdRepeated)
        .then(data => {
          this.tokenLogin(data.token)
          return true
        })
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    hasRole: (state) => (role) => {
      return includes(state.roles, role) || state.isAdmin
    },
    isAdmin: (state) => includes(state.roles, 'admin'),
    ability: () => new Ability(),
  },
})

import Vue from 'vue'
import './StatusIcon.scss'

const StatusIcon = {
  functional: true,
  props: {
    tooltip: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      required: true,
    },
    status: {
      type: Boolean,
      required: true,
    },
    statusColor: {
      type: String,
      default: 'green',
    },
  },
  render: function (h, context) {
    const status = context.props.status
    const tooltipContent = context.props.tooltip

    const topData = {
      staticClass: 'statusicon',
      attrs: { },
    }

    if(tooltipContent) {
      topData.attrs['data-tooltip'] = tooltipContent
    }

    const children = [
      h('i', {
        staticClass: 'v-icon notranslate mdi theme--light mr-2',
        class: context.props.icon,
      }),
    ]

    if(status) {
      children.push(
        h('span', {
          staticClass: 'badge ' + context.props.statusColor,
        }),
      )
    }
    return h('span', topData, children)
  },
}

Vue.component('status-icon', StatusIcon)

export default StatusIcon

import { useAuthStore } from '@/stores/auth'
import { subject } from '@casl/ability'

function loadView(view) {
  return () => import(/* webpackChunkName: "view-[request]" */ '../views/' + view + '.vue')
}

function canNavigate(route) {
  const authStore = useAuthStore()
  const allowedPath = route.matched.every(r => {
    return authStore.ability.can('view', subject('Route', {
      name: r.name, meta: r.meta, path: r.path, params: r.params,
    }))
  })
  return allowedPath
}

export {
  loadView,
  canNavigate,
}

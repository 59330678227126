/* eslint-disable no-console */

import { register } from 'register-service-worker'

/**
 * Note: pwaState is used from UpdateNotification.vue
 */
export const pwaState = {
  waitingWorker: undefined,
  offline: false,
}

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB',
      )
    },
    registered() {
      console.log('Service worker has been registered.')
    },
    cached() {
      console.log('Content has been cached for offline use.')
    },
    updatefound() {
      console.log('New content is downloading.')
    },
    updated(registration) {
      console.log('New content is available; please refresh.')
      pwaState.waitingWorker = registration.waiting
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.')
      pwaState.offline = true
    },
    error(error) {
      console.error('Error during service worker registration:', error)
    },
  })

  if('serviceWorker' in navigator) {
    var refreshing
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (refreshing) return
      window.location.reload()
      refreshing = true
    })
  }
}

import Transformer from './Transformer'
import { dateISOtoCH, dateCHtoISO } from '@/utils/dates'
import { phoneToJSON, phoneFromJSON } from './utils'

export default class PersonTransformer extends Transformer {
  static fetch(person) {
    const e = {
      ...person,
      date_of_birth: person.date_of_birth !== null ? dateISOtoCH(person.date_of_birth) : null,
      phone: phoneFromJSON(person.phone),
      ice_phone: phoneFromJSON(person.ice_phone),
    }
    return e
  }

  static send(person) {
    const e = {
      ...person,
      date_of_birth: person.date_of_birth ? dateCHtoISO(person.date_of_birth) : null,
      phone: phoneToJSON(person.phone),
      ice_phone: phoneToJSON(person.ice_phone),
    }
    return e
  }
}

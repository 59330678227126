var render = function render(_c,_vm){return _c('div',{class:[
    {
      'event-alternative': _vm.props.event.alternative_title,
      'event-canceled': _vm.props.event.status == 'canceled',
    },
    _vm.props.textSize || 'subtitle-2',
  ]},[_c('span',[_vm._v(_vm._s(_vm.props.event.title))]),(_vm.props.event.alternative_title)?_c('span',[_vm._v(_vm._s(_vm.props.event.alternative_title))]):_vm._e(),(_vm.props.event.status == 'canceled')?_c('span',[_vm._v("Abgesagt")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
import { defineStore } from 'pinia'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import union from 'lodash/union'
import remove from 'lodash/remove'
import eventService from '@/services/event'
import Vue from 'vue'

export const useEventStore = defineStore('event', {
  state: () => ({
    events: [],
    meta: {
      kinds: [],
      organisations: [],
      years: [],
    },
    loaded_year: undefined,
    filters: {},
    meta_loaded: false,
  }),
  actions: {
    initialize() {
      return eventService.meta()
        .then(data => {
          this.meta = data
        })
    },
    fetchEvents({ year, filters = {} }) {
      if(this.loaded_year === year && JSON.stringify(filters) === this.filters) {
        return new Promise(resolve => {
          resolve()
        })
      }
      return eventService.list({ year, ...filters }, 300)
        .then((response) => {
          const items = response.data.items
          console.log(`Store got ${items.length} new events`)
          this.events = items
          this.loaded_year = year
          this.filters = JSON.stringify(filters)
        })
    },
    getEvent(id) {
      return eventService.get(id)
        .then((event) => {
          this._updateEvent(event)
          return event
        })
    },
    createEvent(event) {
      return eventService.create(event)
        .then((event) => {
          this.events.push(event)
          const year = (new Date(event.start_date)).getUTCFullYear()
          Vue.set(this.meta, ['years', union(this.meta.years, [year])])
          return event
        })
    },
    updateEvent(event) {
      return eventService.update(event)
        .then((event) => {
          this._updateEvent(event)
          const year = (new Date(event.start_date)).getUTCFullYear()
          Vue.set(this.meta, ['years', union(this.meta.years, [year])])
          return event
        })
    },
    updateEventSheet({ event, form }) {
      return eventService.addUpload(event, form)
        .then((event) => {
          this._updateEvent(event)
          return event
        })
    },
    deleteEvent(event) {
      return eventService.remove(event)
        .then(event => {
          remove(this.events, e => e.id === event.id)
          return event
        })
    },
    deletePDF(event) {
      return eventService.removeUpload(event)
        .then(event => {
          this._updateEvent(event)
          return event
        })
    },
    _updateEvent(event) {
      const idx = findIndex(this.events, (elem) => elem.id === event.id)
      if(idx < 0) {
        return
      }
      Vue.set(this.events, idx, event)
    },
  },
  getters: {
    getById: (state) => (id) => {
      id = parseInt(id)
      return state.events.find(event => event.id === id)
    },
    getByYear: (state) => (year) => {
      year = parseInt(year)
      if(isNaN(year)) {
        return []
      }
      return state.events.filter(e => {
        return parseInt(e.start_date.split('.')[2]) === year
      })
    },
    getEventKindByValue: (state) => (value) => {
      const kind = find(state.meta.kinds, (elem) => elem.value === value)
      if(!kind) {
        return 'Unbekannt'
      }
      return kind.text
    },
  },
})

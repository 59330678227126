import Transformer from './Transformer'

import { dateISOtoCH, dateCHtoISO } from '@/utils/dates'

function trimToNull(data) {
  if(!data || !data.trim()) {
    return null
  }
  return data.trim()
}

export default class EventTransformer extends Transformer {
  static fetch(event) {
    const e = {
      ...event,
      start_date: dateISOtoCH(event.start_date),
      end_date: dateISOtoCH(event.end_date),
      $type: 'Event',
    }
    return e
  }

  static send(event) {
    const e = {
      ...event,
      start_date: dateCHtoISO(event.start_date),
      end_date: dateCHtoISO(event.end_date),
      max_participants: event.max_participants || null, // Send null instead of empty string
      alternative_title: trimToNull(event.alternative_title),
      alternative_description: trimToNull(event.alternative_description),
    }
    delete e.$type
    delete e.images
    return e
  }
}

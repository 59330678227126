import VueRouter from 'vue-router'
import eventRoutes from './events'
import mailRoutes from './mail'
import adminRoutes from './admin'
import photoRoutes from './photos'
import { loadView, canNavigate } from './helpers'
import { createNotification } from '@/utils'
import { useAuthStore } from '@/stores/auth'


const routes = [
  {
    name: 'Login',
    path: '/login',
    component: loadView('Login'),
    meta: {
      public: true,
      guest: true, // The login route requires a guest
    },
  },
  {
    name: 'PWDResetInit',
    path: '/pwdreset1',
    component: loadView('PWDResetInit'),
    meta: {
      public: true,
      guest: true,
    },
  },
  {
    name: 'PWDReset',
    path: '/pwdreset',
    component: loadView('PWDReset'),
    meta: {
      public: true,
      guest: true,
    },
  },
  mailRoutes,
  adminRoutes,
  photoRoutes,
  {
    path: '/',
    component: loadView('Main'),
    meta: {
      auth: true, // All child routes are protected with auth
    },
    children: [
      {
        name: 'Dashboard',
        path: '/',
        component: loadView('Dashboard'),
      },
      {
        name: 'Members',
        path: '/members',
        component: loadView('Members'),
        meta: {
          waitScrollTrigger: true,
          roles: ['manager', 'guide'],
        },
      },
      ...eventRoutes,
      {
        name: 'Guests',
        path: '/guests',
        component: loadView('Guests'),
        meta: {
          waitScrollTrigger: true,
          roles: ['manager', 'guide'],
        },
      },
      {
        name: 'Accounts',
        path: '/accounts',
        component: loadView('Accounts'),
        meta: {
          roles: ['manager'],
        },
      },
      {
        name: 'Statistics',
        path: '/statistics',
        component: loadView('Statistics'),
        meta: {
          roles: ['manager'],
        },
      },
      {
        name: 'Profile',
        path: '/profile',
        component: loadView('Profile'),
      },
      {
        name: 'Tools',
        path: '/tools',
        component: loadView('Tools'),
        meta: {
          roles: ['manager'],
        },
      },
    ],
  },
  {
    path: '*',
    name: 'ErrorPage',
    component: loadView('ErrorPage'),
    meta: {
      public: true,
    },
  },
]

// eslint-disable-next-line
if(typeof webpackHotUpdate !== 'undefined') {
  console.log('Running in webpack hot reload mode. Adding Demo page')
  routes.splice(-2, 0, {
    name: 'Demo',
    path: '/demo',
    component: loadView('Demo'),
  })
  routes.splice(-2, 0, {
    name: 'Media',
    path: '/browser',
    component: loadView('Browser'),
  })
}

const router = new VueRouter({
  mode: 'history',
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    // Idea taken from https://github.com/quasarframework/quasar/issues/1466
    return new Promise(resolve => {
      const position = savedPosition || {}
      // Use the saved position if available
      if(!savedPosition) {
        // Check if a hash is given, otherwise scroll to top
        if(to.hash) {
          position.selector = to.hash
          position.offset = { y: 150 }
        } else {
          position.x = 0
          position.y = 0
        }
      }

      // If a triggerScroll event is sent by the view, add waitScrollTrigger: true to the route meta!
      if(to.matched.some((m) => m.meta.waitScrollTrigger)) {
        // Wait for the event and resolve on the next tick
        this.app.$root.$once('triggerScroll', () => {
          this.app.$nextTick(() => {
            resolve(position)
          })
        })
      } else {
        // Do not wait for the triggerScroll event, resolve immediately
        resolve(position)
      }
    })
  },
})


router.beforeEach((to, from, next) => {
  console.log(`Navigating from ${from.path}${from.hash} to ${to.path}${to.hash}`)

  // Prevent endless loop in navigation
  if(to.name === 'Login') {
    next()
    return
  }

  function navigate() {
    if(canNavigate(to)) {
      next()
    } else {
      // This is a primitive way to check if the user is logged in but does not have
      // the permissions to visit the target
      if(canNavigate(router.resolve('/').route)) {
        if(from.name === null) {
          // Not comming from a specific page / the user entered the url manually
          next({ name: 'Dashboard' })
        } else {
          createNotification('Dir fehlen die Berechtigungen um diesen Bereich anzuzeigen!', 'warning')
          next(false)
        }
      } else {
        // User not logged in, going to login page
        next({ name: 'Login', query: { next: to.fullPath } })
      }
    }
  }

  const authStore = useAuthStore()
  if(!authStore.initialized) {
    authStore.fetchAccessToken().catch(() => {}).finally(() => {
      navigate()
    })
  } else {
    navigate()
  }
})

export default router

import { parseNumber, formatNumber } from 'libphonenumber-js'

function phoneFromJSON(phone) {
  if(!phone) {
    return ''
  }
  const parsed = parseNumber(phone)
  if(parsed.phone) {
    return formatNumber(parsed, 'International')
  }
  // Return original text if parsing failed
  return phone
}

function phoneToJSON(phone) {
  return phone ? formatNumber(parseNumber(phone, 'CH'), 'E.164') : null
}

export {
  phoneFromJSON,
  phoneToJSON,
}

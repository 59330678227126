import * as Sentry from '@sentry/browser'
import { useNotificationStore } from './stores/notification'

const isDebug = process.env.NODE_ENV !== 'production'

function createNotification(text, type = 'info') {
  useNotificationStore().createNotification({ type: type, text: text })
}

function handleApiError(error, respErrorTxt = 'Fehler bei der Serveranfrage.') {
  window.lastError = error
  if (error.response) {
    console.log(error.response.data)
    console.log(error.response.status)
    createNotification(`${respErrorTxt} (Fehler ${error.response.status})`, 'error')
  } else if (error.request) {
    console.log(error.request)
    createNotification('Server antwortet nicht auf Anfrage.', 'error')
  } else {
    console.error(error.message)
    createNotification('Unbekannter Fehler', 'error')
    if (!isDebug) { // If the error is unknown, log it with raven
      Sentry.captureException(error)
    }
  }
}

export {
  handleApiError,
  createNotification,
}

import { defineStore } from 'pinia'

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    notifications: [],
  }),
  actions: {
    createNotification(data) {
      const obj = { ...data, timeout: 4000, show: true }
      this.notifications.push(obj)
      setTimeout(() => {
        this.deleteNotification(obj)
      }, obj.timeout + 100)
      return obj
    },
    deleteNotification(notification) {
      this.notifications.splice(this.notifications.indexOf(notification), 1)
    },
  },
})

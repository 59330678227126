import axios from '@/plugins/axios'

import PersonTransformer from '@/transformers/PersonTransformer'

const PER_PAGE = 750
const END_POINT = '/api/v1/persons'

function list() {
  let persons = []

  function fetch(page = 1) {
    console.log(`Fetching person page ${page}`)
    return axios.get(END_POINT, { params: { page, per_page: PER_PAGE } })
      .then((response) => {
        persons = [...persons, ...response.data.items]
        if (response.data.page < response.data.pages) {
          return fetch(response.data.page + 1)
        } else {
          return PersonTransformer.fetchCollection(persons)
        }
      })
  }

  return fetch()
}

function get(id) {
  return axios.get(`${END_POINT}/${id}`)
    .then((response) => {
      return PersonTransformer.fetch(response.data)
    })
}

function create(person) {
  return axios.post(END_POINT, PersonTransformer.send(person))
    .then((response) => {
      return PersonTransformer.fetch(response.data)
    })
}

function update(person) {
  return axios.put(`${END_POINT}/${person.id}`, PersonTransformer.send(person))
    .then((response) => {
      return PersonTransformer.fetch(response.data)
    })
}

function remove(person) {
  return axios.delete(`${END_POINT}/${person.id}`)
    .then((response) => {
      return {}
    })
}

function search(query) {
  return axios.get(`${END_POINT}/search`, { params: { q: query } })
    .then(response => response.data.items)
}

function merge(memberId, guestId, options = { preview: true }) {
  console.log(`Merging guest ${guestId} with member ${memberId}`)
  const method = options.preview ? axios.get : axios.post
  return method(`${END_POINT}/${memberId}/merge/${guestId}`)
    .then(response => {
      return PersonTransformer.fetch(response.data)
    })
}

function getEvents(person) {
  return axios.get(`${END_POINT}/${person.id}/events`)
    .then(response => {
      return response.data
    })
}

export default {
  list,
  get,
  create,
  update,
  remove,
  search,
  merge,
  getEvents,
}

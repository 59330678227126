import Vue from 'vue'
import Vuetify, {
  VExpansionPanel, VExpansionPanelHeader, VExpansionPanelContent,
  VRow, VCol, VIcon, VBtn, VAppBar, VToolbar, VApp, VContent, VContainer,
  VCard, VCardTitle, VCardText, VCardActions, VSpacer, VBadge, VTooltip, VMain,
  VListItem, VTextField,
} from 'vuetify/lib'
import de from 'vuetify/es5/locale/de'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify, {
  components: {
    // Note: These components are used in functional components and must be registered explicitly
    VApp,
    VAppBar,
    VBadge,
    VBtn,
    VCard,
    VCardActions,
    VCardText,
    VCardTitle,
    VCol,
    VContainer,
    VContent,
    VExpansionPanel,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VIcon,
    VListItem,
    VMain,
    VRow,
    VSpacer,
    VTextField,
    VToolbar,
    VTooltip,
  },
})

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: colors.blue.darken2,
        secondary: colors.blue.accent2,
        accent: colors.grey.darken3,
        error: colors.red.accent2,
        info: colors.blue.base,
        success: colors.green.base,
        warning: colors.amber.base,
      },
    },
  },
  options: {
    customProperties: false,
  },
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { de },
    current: 'de',
  },
})


import axios from '@/plugins/axios'

const END_POINT = '/api/v1/auth'


function login(email, password) {
  return axios.post(END_POINT + '/login', { email, password }, { skipRetry: true })
    .then(response => response.data)
}

function refresh() {
  return axios.get(END_POINT + '/refresh', { skipRetry: true, noAuthHeader: true })
    .then(response => response.data)
}

function logout() {
  return axios.get(END_POINT + '/logout').then(response => response.data)
}

function pwdresetInit(email) {
  return axios.post('/api/v1/auth/reset-token', { email })
    .then(response => response.data)
}

function pwdreset(token, password) {
  return axios.post('/api/v1/auth/pwdreset', { token, password })
    .then(response => response.data)
}

function pwdChange(oldPassword, newPassword, newPasswordRep) {
  return axios.post('/api/v1/auth/change-pwd', {
    old_password: oldPassword,
    password: newPassword,
    password_repeated: newPasswordRep,
  }, {
    skipRetry: true,
  })
    .then(response => response.data)
}


export default {
  login,
  refresh,
  logout,
  pwdresetInit,
  pwdreset,
  pwdChange,
}

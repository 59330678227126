
const Events = () => import(/* webpackChunkName: "Events" */ '@/views/Events.vue')
const EventEdit = () => import(/* webpackChunkName: "EventEdit" */ '@/views/EventEdit.vue')
const EventView = () => import(/* webpackChunkName: "EventView" */ '@/views/EventView.vue')

export default [
  {
    name: 'Events',
    path: '/events/:year(\\d+)?',
    component: Events,
    meta: {
      waitScrollTrigger: true,
    },
  },
  {
    name: 'EventEdit',
    path: '/event/:id(\\d+|new)',
    component: EventEdit,
  },
  {
    name: 'EventView',
    path: '/event/:id(\\d+)/view',
    component: EventView,
  },
]
